.mobsubVideo{
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);

}
.mobpubVideo {
    border-color: #ff3c3c;
    position: absolute;
    left:50%;
    transform:translate(-50%) rotateY(180deg);
}