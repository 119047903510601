
.priceBefore{
    position: relative;
    color: #7f94a1;
    font-size: 14px;
    font-family: 'PT Sans'
}

.priceBefore:before {
    border-bottom: 1px solid gray;
    position: absolute;
    content: "";
    width: 100%;
    height: 70%;

}


/*
fontFamily:"PT Sans";
fontSize:160;
borderBottomWidth:2;
borderBottomStyle:"solid";
borderBottomColor:"red";
width:100%;
height:50%;
transform: rotate(-12deg);*/