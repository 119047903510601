
.fastbtn{
    height: 38px;
    margin-top: 7px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: rgba(255,255,255,0.9);
    border-radius: 5px;
    background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
    background-size: 400%;
    animation: anime 16s linear infinite;
    border: none;
}



@keyframes anime{
0% {background-position: 0% 50%}
50% {background-position: 100% 50%}
100% { background-position: 0% 50%;}
}