.registration{
    height: 38px;
    text-align: center;
    color: rgba(255,255,255,0.9);
    border-radius: 5px;
    background: linear-gradient(-45deg, #ff3c3c, #ff8e00, #3e9ccd, #3CF0C5);
    background-size: 400%;
    margin-top: 0px;
    animation: anime 16s linear infinite;
    border: none;
}



@keyframes anime{
    0% {background-position: 0% 50%}
    50% {background-position: 100% 50%}
    100% { background-position: 0% 50%;}
}